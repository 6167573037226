import { __decorate } from "tslib";
import { appName } from '@/env';
import { financeiroParcelaStore, mainStore, userToolStore, userActionStore } from '@/store';
import _ from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import DrivePremium from './drivePremium/DrivePremium.vue';
import ShowDialog from '@/components/ShowDialog.vue';
const routeGuardMain = async (to, from, next) => {
    if (to.path === '/main') {
        next('/main/dashboard');
    }
    else {
        next();
    }
};
let Main = class Main extends Vue {
    constructor() {
        super(...arguments);
        this.menuItems = [
            {
                title: 'Blog KNN Idiomas',
                link: 'https://www.knnidiomas.com.br/blog-do-aluno/',
            },
            {
                title: 'KNN Dream Hub',
                link: 'https://dreamhub.knnidiomas.com.br/',
            },
        ];
        this.socialMedia = [
            {
                link: 'https://www.facebook.com/knnidiomasoficial/',
                img: 'facebook',
            },
            {
                link: 'https://www.instagram.com/knnidiomasbrasil/',
                img: 'instagram',
            },
            {
                link: 'https://www.youtube.com/user/knnidiomas',
                img: 'youtube',
            },
            { link: 'https://twitter.com/knn_brasil', img: 'twitter' },
        ];
        this.appName = appName;
        this.selectedSearchTool = { elementId: '', view: '' };
        this.loading = true;
        this.showFinanceiroLink = false;
        this.selectedDrawer = null;
        this.routerPath = '';
    }
    beforeRouteEnter(to, from, next) {
        routeGuardMain(to, from, next);
    }
    beforeRouteUpdate(to, from, next) {
        routeGuardMain(to, from, next);
    }
    handleDrawer(route) {
        if (!!route.path) {
            this.selectedDrawer = route.path;
            // se a rota é diferente antes de navegar.
            if (this.$route.path !== route) {
                this.$router.push(route);
            }
        }
        else if (!!route.tool_id) {
            this.selectedDrawer = route.tool_id;
        }
    }
    onChange(val) {
        const item = val.replace(/\s+/g, '').split('-');
        let name = item[0];
        let group = item[1];
        let path = '';
        const nameMapping = {
            'CheckIn': { name: 'Prospex', group: 'Operacional' },
            'Agendaraulaplus': { name: 'Agendaraulaextra', group: 'Operacional' },
            'Controleaulaplus': { name: 'Controleaulaextra', group: 'Pedagógico' },
            'SDR': { name: 'CDA', group: 'Comercial' },
        };
        if (nameMapping[name]) {
            const { name: newName, group: newGroup } = nameMapping[name];
            name = newName;
            group = newGroup;
        }
        if (!group) {
            path = '/main/' + name.normalize('NFD').replace(/[^a-zA-Zs]/g, '');
        }
        else {
            group = group.normalize('NFD').replace(/[^a-zA-Zs]/g, '');
            path = '/main/' + group + name.normalize('NFD').replace(/[^a-zA-Zs]/g, '');
        }
        return this.$router.push(path);
    }
    getPath(groupName, module) {
        if (groupName != '') {
            groupName = groupName.normalize('NFD').replace(/[^a-zA-Zs]/g, '');
        }
        let path = '/main/' + groupName + module.normalize('NFD').replace(/[^a-zA-Zs]/g, '');
        if (path.includes('portal')) {
            path = path.replace('portal', 'portal/');
        }
        return path;
    }
    get menuItemsTopo() {
        const menusTopo = [
            {
                path: '/main/dashboard',
                icon: 'home',
                title: 'Dashboard',
                condition: true,
            },
            {
                path: '/main/profile/view',
                icon: 'person',
                title: 'Meu Usuário',
                condition: true,
            },
        ];
        if (!this.userProfile.is_aluno) {
            menusTopo.push({
                path: '/main/Helpdesk',
                icon: 'mdi-face-agent',
                title: 'Helpdesk',
                condition: true,
            });
        }
        if (this.allowListaEscolaAtHome) {
            menusTopo.push({
                path: '/main/Pedagogicoturma/aulaonline',
                icon: 'house',
                title: this.userProfile.company.escola_id == 1 ? 'KNN At Home' : 'Phenom Connect',
                condition: true,
            });
        }
        if (this.userActionsVisualizarDataDrivenAllow) {
            menusTopo.push({
                path: '/main/dataDriven',
                icon: 'mdi-chart-line-variant',
                title: 'Data Driven',
                condition: true,
            });
        }
        return menusTopo;
    }
    get userActionsVisualizarDataDrivenAllow() {
        // adicionar outros a medida que for implementando
        return userActionStore.visualizarAbaMarketingDataDrivenAllow;
    }
    get allowListaEscolaAtHome() {
        const tool = userToolStore.userTools.find((item) => item.tool_id === 83);
        if (!tool) {
            return false;
        }
        return tool.allow_view;
    }
    get miniDrawer() {
        return mainStore.dashboardMiniDrawer;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get showDrawer() {
        return mainStore.dashboardShowDrawer;
    }
    set showDrawer(value) {
        mainStore.setDashboardShowDrawer(value);
    }
    get searchOptions() {
        return userToolStore.userToolsForSearch;
    }
    get allItems() {
        return _.orderBy(userToolStore.userTools, 'name', 'asc');
    }
    get hasAdminAccess() {
        return mainStore.hasAdminAccess;
    }
    routeComercial(name) {
        if (name === 'Comercial') {
            this.$router.push('/main/comercial/');
        }
    }
    get modulesGroups() {
        const groups = {
            unicos: [],
        };
        for (const tool of userToolStore.userTools) {
            if (tool.tool_id === 83) {
                continue;
            }
            if (tool.group) {
                if (tool.group.length === 0) {
                    groups.unicos.push(tool);
                }
                if (groups[tool.group]) {
                    groups[tool.group].push(tool);
                }
                else {
                    groups[tool.group] = [tool];
                }
            }
            else {
                groups.unicos.push(tool);
            }
        }
        return groups;
    }
    get isKnnUrl() {
        return window.location.hostname.includes('knn');
    }
    get isPhenomUrl() {
        return window.location.hostname.includes('phenom');
    }
    getLogoUrl() {
        if (this.isKnnUrl && this.userProfile.is_aluno) {
            return 'https://mail.knnidiomas.com.br/knn-master/logoKNNColorida.png';
        }
        else if (this.isPhenomUrl && this.userProfile.is_aluno) {
            return 'https://mail.knnidiomas.com.br/knn-master/logoPhenonColoridoTransparente.png';
        }
        else {
            return 'https://www.drive.app.br/logoDrive.png';
        }
    }
    get financeiroAtrasado() {
        // @ts-ignore
        return _.filter(financeiroParcelaStore.financeiroParcelasDrive, (item) => item.dias_atraso > 0);
    }
    abrirFinanceiro() {
        this.$router.push('/main/Financeiro/');
    }
    onChangeSearchTool() {
        if (this.selectedSearchTool) {
            const elementId = this.selectedSearchTool.elementId;
            this.$router.push({
                name: this.selectedSearchTool.view,
                params: { elementId },
            });
        }
    }
    switchShowDrawer() {
        mainStore.setDashboardShowDrawer(!mainStore.dashboardShowDrawer);
    }
    switchMiniDrawer() {
        mainStore.setDashboardMiniDrawer(!mainStore.dashboardMiniDrawer);
    }
    handleMenuFiscal() {
        this.$router.push({ name: 'main-nota-fiscal-menu-fiscal' });
    }
    async created() {
        this.loading = true;
        await userToolStore.getUserTools();
        this.loading = false;
    }
    openWhatsApp() {
        open(this.getLinkWhatsappWeb());
    }
    async logout() {
        await mainStore.userLogOut();
    }
    get routeParams() {
        return this.$route.params;
    }
    async onChangeRoute(val) {
        if (Object.keys(val).length > 0) {
            const { elementId } = val;
            if (elementId) {
                this.$nextTick(async () => {
                    // @ts-ignore
                    await this.focusElementById(elementId);
                });
            }
        }
    }
    mounted() {
        if (this.userProfile.company.escola_id === 2 && this.userProfile.is_aluno) {
            this.$vuetify.theme.themes.light = {
                primaryfranchising: '#FF6100',
                primary: '#6f18a8',
                secondary: '#008bbd',
                accent: '#FF6304',
                redmain: '#F70052',
                redsecondary: '#FF4A86',
                red900: '#B81148',
                red500: '#FF7BA7',
                red400: '#FFA7C4',
                orangemain: '#FF6100',
                orangesecondary: '#FF8133',
                orange600: '#FF8D55',
                orange500: '#FF8D55',
                purplemain: '#6400AC',
                purplemain2: '#621F9A',
                purplesecondary: '#7F51C7',
                purple500: '#A77DE8',
                purple300: '#D2B6FF',
                blue900: '#12032B',
                blue850: '#1A0A33',
                blue800: '#271A5D',
                bluemain: '#3E009F',
                bluesecondary: '#4A2DA3',
                blue300: '#645FC9',
                blue200: '#8883F7',
                blue150: '#C4C1FF',
                blue100: '#D6D3FF',
                gray700: '#313943',
                gray500: '#5E6D7E',
                gray300: '#A2AFBD',
                gray200: '#BEC5D7',
                gray100: '#CFD9E3',
                gray50: '#E6EDF4',
                gray25: '#F2F7FF',
                white: '#FAFCFF',
                green900: '#257A4C',
                green700: '#00B854',
                green300: '#C2F2D8',
                info: this.$vuetify.theme.themes.light.info,
                warning: this.$vuetify.theme.themes.light.warning,
                error: this.$vuetify.theme.themes.light.error,
                success: this.$vuetify.theme.themes.light.success,
            };
        }
    }
};
__decorate([
    Watch('routerPath')
], Main.prototype, "onChange", null);
__decorate([
    Watch('routeParams', { deep: true })
], Main.prototype, "onChangeRoute", null);
Main = __decorate([
    Component({
        components: {
            DrivePremium,
            ShowDialog,
        }
    })
], Main);
export default Main;
